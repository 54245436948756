@font-face {
    font-family: 'SFProText_Regular';
    src: url('/static/fonts/SFProText-Regular.eot');
    src: url('/static/fonts/SFProText-Regular.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProText-Regular.woff2') format('woff2'),
        url('/static/fonts/SFProText-Regular.woff') format('woff'),
        url('/static/fonts/SFProText-Regular.ttf') format('truetype'),
        url('/static/fonts/SFProText-Regular.svg#SFProText-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SFProText_RegularItalic';
    src: url('/static/fonts/SFProText-RegularItalic.eot');
    src: url('/static/fonts/SFProText-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProText-RegularItalic.woff2') format('woff2'),
        url('/static/fonts/SFProText-RegularItalic.woff') format('woff'),
        url('/static/fonts/SFProText-RegularItalic.ttf') format('truetype'),
        url('/static/fonts/SFProText-RegularItalic.svg#SFProText-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'SFProDisplay_Regular';
    src: url('/static/fonts/SFProDisplay-Regular.eot');
    src: url('/static/fonts/SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-Regular.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-Regular.woff') format('woff'),
        url('/static/fonts/SFProDisplay-Regular.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-Regular.svg#SFProDisplay-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SFProDisplay_RegularItalic';
    src: url('/static/fonts/SFProDisplay-RegularItalic.eot');
    src: url('/static/fonts/SFProDisplay-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-RegularItalic.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-RegularItalic.woff') format('woff'),
        url('/static/fonts/SFProDisplay-RegularItalic.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-RegularItalic.svg#SFProDisplay-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'SFProText_Light';
    src: url('/static/fonts/SFProText-Light.eot');
    src: url('/static/fonts/SFProText-Light.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProText-Light.woff2') format('woff2'),
        url('/static/fonts/SFProText-Light.woff') format('woff'),
        url('/static/fonts/SFProText-Light.ttf') format('truetype'),
        url('/static/fonts/SFProText-Light.svg#SFProText-Light') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'SFProText_LightItalic';
    src: url('/static/fonts/SFProText-LightItalic.eot');
    src: url('/static/fonts/SFProText-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProText-LightItalic.woff2') format('woff2'),
        url('/static/fonts/SFProText-LightItalic.woff') format('woff'),
        url('/static/fonts/SFProText-LightItalic.ttf') format('truetype'),
        url('/static/fonts/SFProText-LightItalic.svg#SFProText-LightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'SFProDisplay_Light';
    src: url('/static/fonts/SFProDisplay-Light.eot');
    src: url('/static/fonts/SFProDisplay-Light.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-Light.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-Light.woff') format('woff'),
        url('/static/fonts/SFProDisplay-Light.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-Light.svg#SFProDisplay-Light') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'SFProDisplay_LightItalic';
    src: url('/static/fonts/SFProDisplay-LightItalic.eot');
    src: url('/static/fonts/SFProDisplay-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-LightItalic.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-LightItalic.woff') format('woff'),
        url('/static/fonts/SFProDisplay-LightItalic.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-LightItalic.svg#SFProDisplay-LightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'SFProText_Medium';
    src: url('/static/fonts/SFProText-Medium.eot');
    src: url('/static/fonts/SFProText-Medium.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProText-Medium.woff2') format('woff2'),
        url('/static/fonts/SFProText-Medium.woff') format('woff'),
        url('/static/fonts/SFProText-Medium.ttf') format('truetype'),
        url('/static/fonts/SFProText-Medium.svg#SFProText-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'SFProText_MediumItalic';
    src: url('/static/fonts/SFProText-MediumItalic.eot');
    src: url('/static/fonts/SFProText-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProText-MediumItalic.woff2') format('woff2'),
        url('/static/fonts/SFProText-MediumItalic.woff') format('woff'),
        url('/static/fonts/SFProText-MediumItalic.ttf') format('truetype'),
        url('/static/fonts/SFProText-MediumItalic.svg#SFProText-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'SFProDisplay_Medium';
    src: url('/static/fonts/SFProDisplay-Medium.eot');
    src: url('/static/fonts/SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-Medium.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-Medium.woff') format('woff'),
        url('/static/fonts/SFProDisplay-Medium.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-Medium.svg#SFProDisplay-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'SFProDisplay_MediumItalic';
    src: url('/static/fonts/SFProDisplay-MediumItalic.eot');
    src: url('/static/fonts/SFProDisplay-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-MediumItalic.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-MediumItalic.woff') format('woff'),
        url('/static/fonts/SFProDisplay-MediumItalic.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-MediumItalic.svg#SFProDisplay-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'SFProText_Semibold';
    src: url('/static/fonts/SFProText-Semibold.eot');
    src: url('/static/fonts/SFProText-Semibold.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProText-Semibold.woff2') format('woff2'),
        url('/static/fonts/SFProText-Semibold.woff') format('woff'),
        url('/static/fonts/SFProText-Semibold.ttf') format('truetype'),
        url('/static/fonts/SFProText-Semibold.svg#SFProText-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'SFProText_SemiboldItalic';
    src: url('/static/fonts/SFProText-SemiboldItalic.eot');
    src: url('/static/fonts/SFProText-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProText-SemiboldItalic.woff2') format('woff2'),
        url('/static/fonts/SFProText-SemiboldItalic.woff') format('woff'),
        url('/static/fonts/SFProText-SemiboldItalic.ttf') format('truetype'),
        url('/static/fonts/SFProText-SemiboldItalic.svg#SFProText-SemiboldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'SFProDisplay_Semibold';
    src: url('/static/fonts/SFProDisplay-Semibold.eot');
    src: url('/static/fonts/SFProDisplay-Semibold.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-Semibold.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-Semibold.woff') format('woff'),
        url('/static/fonts/SFProDisplay-Semibold.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-Semibold.svg#SFProDisplay-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'SFProDisplay_SemiboldItalic';
    src: url('/static/fonts/SFProDisplay-SemiboldItalic.eot');
    src: url('/static/fonts/SFProDisplay-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-SemiboldItalic.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-SemiboldItalic.woff') format('woff'),
        url('/static/fonts/SFProDisplay-SemiboldItalic.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-SemiboldItalic.svg#SFProDisplay-SemiboldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'SFProText_Bold';
    src: url('/static/fonts/SFProText-Bold.eot');
    src: url('/static/fonts/SFProText-Bold.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProText-Bold.woff2') format('woff2'),
        url('/static/fonts/SFProText-Bold.woff') format('woff'),
        url('/static/fonts/SFProText-Bold.ttf') format('truetype'),
        url('/static/fonts/SFProText-Bold.svg#SFProText-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SFProText_BoldItalic';
    src: url('/static/fonts/SFProText-BoldItalic.eot');
    src: url('/static/fonts/SFProText-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProText-BoldItalic.woff2') format('woff2'),
        url('/static/fonts/SFProText-BoldItalic.woff') format('woff'),
        url('/static/fonts/SFProText-BoldItalic.ttf') format('truetype'),
        url('/static/fonts/SFProText-BoldItalic.svg#SFProText-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'SFProDisplay_Bold';
    src: url('/static/fonts/SFProDisplay-Bold.eot');
    src: url('/static/fonts/SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-Bold.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-Bold.woff') format('woff'),
        url('/static/fonts/SFProDisplay-Bold.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-Bold.svg#SFProDisplay-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SFProDisplay_BoldItalic';
    src: url('/static/fonts/SFProDisplay-BoldItalic.eot');
    src: url('/static/fonts/SFProDisplay-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-BoldItalic.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-BoldItalic.woff') format('woff'),
        url('/static/fonts/SFProDisplay-BoldItalic.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-BoldItalic.svg#SFProDisplay-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'SFProText_Heavy';
    src: url('/static/fonts/SFProText-Heavy.eot');
    src: url('/static/fonts/SFProText-Heavy.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProText-Heavy.woff2') format('woff2'),
        url('/static/fonts/SFProText-Heavy.woff') format('woff'),
        url('/static/fonts/SFProText-Heavy.ttf') format('truetype'),
        url('/static/fonts/SFProText-Heavy.svg#SFProText-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'SFProText_HeavyItalic';
    src: url('/static/fonts/SFProText-HeavyItalic.eot');
    src: url('/static/fonts/SFProText-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProText-HeavyItalic.woff2') format('woff2'),
        url('/static/fonts/SFProText-HeavyItalic.woff') format('woff'),
        url('/static/fonts/SFProText-HeavyItalic.ttf') format('truetype'),
        url('/static/fonts/SFProText-HeavyItalic.svg#SFProText-HeavyItalic') format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'SFProDisplay_Heavy';
    src: url('/static/fonts/SFProDisplay-Heavy.eot');
    src: url('/static/fonts/SFProDisplay-Heavy.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-Heavy.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-Heavy.woff') format('woff'),
        url('/static/fonts/SFProDisplay-Heavy.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-Heavy.svg#SFProDisplay-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'SFProDisplay_HeavyItalic';
    src: url('/static/fonts/SFProDisplay-HeavyItalic.eot');
    src: url('/static/fonts/SFProDisplay-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-HeavyItalic.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-HeavyItalic.woff') format('woff'),
        url('/static/fonts/SFProDisplay-HeavyItalic.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-HeavyItalic.svg#SFProDisplay-HeavyItalic') format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'SFProDisplay_Black';
    src: url('/static/fonts/SFProDisplay-Black.eot');
    src: url('/static/fonts/SFProDisplay-Black.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-Black.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-Black.woff') format('woff'),
        url('/static/fonts/SFProDisplay-Black.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-Black.svg#SFProDisplay-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'SFProDisplay_BlackItalic';
    src: url('/static/fonts/SFProDisplay-BlackItalic.eot');
    src: url('/static/fonts/SFProDisplay-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-BlackItalic.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-BlackItalic.woff') format('woff'),
        url('/static/fonts/SFProDisplay-BlackItalic.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-BlackItalic.svg#SFProDisplay-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'SFProDisplay_Thin';
    src: url('/static/fonts/SFProDisplay-Thin.eot');
    src: url('/static/fonts/SFProDisplay-Thin.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-Thin.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-Thin.woff') format('woff'),
        url('/static/fonts/SFProDisplay-Thin.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-Thin.svg#SFProDisplay-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'SFProDisplay_ThinItalic';
    src: url('/static/fonts/SFProDisplay-ThinItalic.eot');
    src: url('/static/fonts/SFProDisplay-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-ThinItalic.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-ThinItalic.woff') format('woff'),
        url('/static/fonts/SFProDisplay-ThinItalic.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-ThinItalic.svg#SFProDisplay-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'SFProDisplay_Ultralight';
    src: url('/static/fonts/SFProDisplay-Ultralight.eot');
    src: url('/static/fonts/SFProDisplay-Ultralight.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-Ultralight.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-Ultralight.woff') format('woff'),
        url('/static/fonts/SFProDisplay-Ultralight.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-Ultralight.svg#SFProDisplay-Ultralight') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'SFProDisplay_UltralightItalic';
    src: url('/static/fonts/SFProDisplay-UltralightItalic.eot');
    src: url('/static/fonts/SFProDisplay-UltralightItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-UltralightItalic.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-UltralightItalic.woff') format('woff'),
        url('/static/fonts/SFProDisplay-UltralightItalic.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-UltralightItalic.svg#SFProDisplay-UltralightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
}