.my-header {
  position: fixed;
  width: calc(100% - 40px);
  left: 0;
  top: 0;
  height: auto;
  background-color: #ffffff;
  z-index: 999;
  padding: 20px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  .container-1 {
    .item-in {
      &:first-child {
        img {
          max-width: 200px;
          @media only screen and (max-width: 767px) {
            width: 120px;
          }
        }
      }
      &:last-child {
        text-align: right;
        .logout-btn {
          padding: 4px 6px 6px 10px;
          color: $red;
          font-weight: 600;
          height: 100%;
        }
        .notifications-button {
          float: right;
          color: $warning;
        }
        @media only screen and (max-width: 427px) {
          h5 {
            height: 100%;
            padding-top: 5px;
            .logout-btn {
              height: auto;
              padding: 0px 6px 0px 10px;
            }
          }
        }
      }
    }
  }
}

// LOGIN

.logo-login {
  max-width: 200px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.card-login {
  padding: 40px;
  h1 {
    margin-bottom: 20px;
    color: $green;
  }
  .form-login {
    .form-login-field {
      width: 100%;
      margin-bottom: 40px;
    }
  }
}

.form-login-field > div:after {
  border-bottom: 2px solid $green !important;
}

.form-login-field_label-focus {
  color: $green !important;
}

// BUTTON

.btn-sinko {
  background-color: $green !important;
  color: rgba(0, 0, 0, 0.54) !important;
  font-weight: 700 !important;
  svg {
    margin-left: 7px;
  }
}

.btn-file {
  width: 100%;
  background-color: $dark-green !important;
  color: $white !important;
}

// LIST PAGE

.list-tab-sinko {
  position: absolute !important;
  left: 0;
  bottom: 0;
  margin-bottom: 0;
  height: calc(100% - 95px);
  .p-b-50 {
    padding-bottom: 50px;
  }
  .MuiTabs-root-208 {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  .custom-label-color {
    position: absolute !important;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: $green;
    button {
      span {
        span {
          span {
            font-weight: 700 !important;
          }
        }
      }
    }
  }
}

.font-tab {
  font-weight: 700 !important;
  @media only screen and (max-width: 767px) {
    font-size: 11px !important;
  }
}

.search-box {
  margin-top: 0;
  .search-input {
    width: calc(100% - 48px);
  }
}

.content-tab {
  padding-top: 100px;
  padding-bottom: 70px;
}

// FORM VISITA

.form-visits {
  .input-vists {
    width: 100%;
    div {
      &:after {
        border-bottom: 2px solid $green !important;
      }
    }
  }
}

.margin-input {
  margin-top: 32px !important;
}

@media only screen and (max-width: 850px) {
  .resposive-filter {
    .responsive-from {
      width: calc(50% - 8px);
      margin: 0 !important;
      margin-top: 16px !important;
      padding-right: 8px;
    }
    .responsive-to {
      width: calc(50% - 8px);
      margin: 0 !important;
      margin-top: 16px !important;
      padding-left: 8px;
      label {
        padding-left: 8px;
      }
    }
    .responsive-select-agent {
      width: calc(50% - 8px);
      margin: 0 !important;
      margin-top: 16px !important;
      padding-right: 8px;
    }
  }
}

// TABELLA

.p-title-table {
  padding: 24px 24px 0px 24px;
  position: relative;
  .map-button {
    position: absolute;
    left: 140px;
    top: 18px;
    background-color: $dark-green;
    color: $white;
    &:hover {
      background-color: $green;
    }
  }
}

.no-padding {
  padding: 4px !important;
  &.first {
    padding-left: 24px !important;
  }
}

// MODAL

.modal {
  .modal-title {
    background-color: $green;
    h2 {
      color: $white;
      text-align: center;
    }
    .btn-close {
      position: absolute;
      top: 6px;
      right: 6px;
      color: $white;
    }
  }
  .modal-body {
    .memo-cont {
      position: relative;
      border-bottom: 2px solid $light-grey;
      text-align: center;
      padding-top: 16px;
      padding-bottom: 16px;
      .visit-state {
        background-color: $dark-green;
        color: $white;
        margin-bottom: 8px;
      }
      h3 {
        color: $black;
        margin-top: 0;
        margin-bottom: 5px;
        font-size: 20px;
        font-weight: 200;
        span {
          font-weight: 400;
          color: $green;
        }
      }

      h4 {
        margin-top: 0;
        font-weight: 400;
      }
      p {
        color: $black;
        font-style: italic;
        margin: 15px 0;
      }
      button {
        color: $white;
        min-width: 120px;
      }
    }
  }
}

// ACTIVITIES

.cont-map {
  height: 600px;
  @media only screen and (max-width: 991px) {
    height: 100%;
  }
}
.cont-marker {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .info-box {
    height: auto;
    width: 230px;
    text-align: center;
    background-color: $white;
    position: absolute;
    top: -140px;
    left: -250px;
    border-radius: 20px;
    border-bottom-right-radius: 0;
    padding: 8px 8px 20px 8px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    h2 {
      margin-top: 0;
      margin-bottom: 5px;
    }
    p {
      margin-top: 0;
      margin-bottom: 15px;
      color: $dark-grey;
    }
    button {
      background-color: $dark-green;
      color: $white;
    }
  }
}

.map-error-text {
  text-align: center;
  padding: 20px;
}

.marker1 {
  color: #019640;
}
.marker2 {
  color: #3885cf;
}
.marker3 {
  color: #d63131;
}

.attachment-cont {
  display: -webkit-flex; /* Safari */
  display: flex !important;
  .attachment-name {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    cursor: pointer;
    padding-left: 12px;
    padding-top: 12px;
    text-decoration: underline;
  }
  .attachment-del {
    margin-top: 8px;
    cursor: pointer;
  }
}
