@font-face {
    font-family: 'SFProText_Regular';
    src: url('/static/fonts/SFProText-Regular.eot');
    src: url('/static/fonts/SFProText-Regular.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProText-Regular.woff2') format('woff2'),
        url('/static/fonts/SFProText-Regular.woff') format('woff'),
        url('/static/fonts/SFProText-Regular.ttf') format('truetype'),
        url('/static/fonts/SFProText-Regular.svg#SFProText-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SFProText_RegularItalic';
    src: url('/static/fonts/SFProText-RegularItalic.eot');
    src: url('/static/fonts/SFProText-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProText-RegularItalic.woff2') format('woff2'),
        url('/static/fonts/SFProText-RegularItalic.woff') format('woff'),
        url('/static/fonts/SFProText-RegularItalic.ttf') format('truetype'),
        url('/static/fonts/SFProText-RegularItalic.svg#SFProText-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'SFProDisplay_Regular';
    src: url('/static/fonts/SFProDisplay-Regular.eot');
    src: url('/static/fonts/SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-Regular.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-Regular.woff') format('woff'),
        url('/static/fonts/SFProDisplay-Regular.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-Regular.svg#SFProDisplay-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SFProDisplay_RegularItalic';
    src: url('/static/fonts/SFProDisplay-RegularItalic.eot');
    src: url('/static/fonts/SFProDisplay-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-RegularItalic.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-RegularItalic.woff') format('woff'),
        url('/static/fonts/SFProDisplay-RegularItalic.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-RegularItalic.svg#SFProDisplay-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'SFProText_Light';
    src: url('/static/fonts/SFProText-Light.eot');
    src: url('/static/fonts/SFProText-Light.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProText-Light.woff2') format('woff2'),
        url('/static/fonts/SFProText-Light.woff') format('woff'),
        url('/static/fonts/SFProText-Light.ttf') format('truetype'),
        url('/static/fonts/SFProText-Light.svg#SFProText-Light') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'SFProText_LightItalic';
    src: url('/static/fonts/SFProText-LightItalic.eot');
    src: url('/static/fonts/SFProText-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProText-LightItalic.woff2') format('woff2'),
        url('/static/fonts/SFProText-LightItalic.woff') format('woff'),
        url('/static/fonts/SFProText-LightItalic.ttf') format('truetype'),
        url('/static/fonts/SFProText-LightItalic.svg#SFProText-LightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'SFProDisplay_Light';
    src: url('/static/fonts/SFProDisplay-Light.eot');
    src: url('/static/fonts/SFProDisplay-Light.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-Light.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-Light.woff') format('woff'),
        url('/static/fonts/SFProDisplay-Light.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-Light.svg#SFProDisplay-Light') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'SFProDisplay_LightItalic';
    src: url('/static/fonts/SFProDisplay-LightItalic.eot');
    src: url('/static/fonts/SFProDisplay-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-LightItalic.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-LightItalic.woff') format('woff'),
        url('/static/fonts/SFProDisplay-LightItalic.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-LightItalic.svg#SFProDisplay-LightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'SFProText_Medium';
    src: url('/static/fonts/SFProText-Medium.eot');
    src: url('/static/fonts/SFProText-Medium.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProText-Medium.woff2') format('woff2'),
        url('/static/fonts/SFProText-Medium.woff') format('woff'),
        url('/static/fonts/SFProText-Medium.ttf') format('truetype'),
        url('/static/fonts/SFProText-Medium.svg#SFProText-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'SFProText_MediumItalic';
    src: url('/static/fonts/SFProText-MediumItalic.eot');
    src: url('/static/fonts/SFProText-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProText-MediumItalic.woff2') format('woff2'),
        url('/static/fonts/SFProText-MediumItalic.woff') format('woff'),
        url('/static/fonts/SFProText-MediumItalic.ttf') format('truetype'),
        url('/static/fonts/SFProText-MediumItalic.svg#SFProText-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'SFProDisplay_Medium';
    src: url('/static/fonts/SFProDisplay-Medium.eot');
    src: url('/static/fonts/SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-Medium.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-Medium.woff') format('woff'),
        url('/static/fonts/SFProDisplay-Medium.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-Medium.svg#SFProDisplay-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'SFProDisplay_MediumItalic';
    src: url('/static/fonts/SFProDisplay-MediumItalic.eot');
    src: url('/static/fonts/SFProDisplay-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-MediumItalic.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-MediumItalic.woff') format('woff'),
        url('/static/fonts/SFProDisplay-MediumItalic.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-MediumItalic.svg#SFProDisplay-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'SFProText_Semibold';
    src: url('/static/fonts/SFProText-Semibold.eot');
    src: url('/static/fonts/SFProText-Semibold.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProText-Semibold.woff2') format('woff2'),
        url('/static/fonts/SFProText-Semibold.woff') format('woff'),
        url('/static/fonts/SFProText-Semibold.ttf') format('truetype'),
        url('/static/fonts/SFProText-Semibold.svg#SFProText-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'SFProText_SemiboldItalic';
    src: url('/static/fonts/SFProText-SemiboldItalic.eot');
    src: url('/static/fonts/SFProText-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProText-SemiboldItalic.woff2') format('woff2'),
        url('/static/fonts/SFProText-SemiboldItalic.woff') format('woff'),
        url('/static/fonts/SFProText-SemiboldItalic.ttf') format('truetype'),
        url('/static/fonts/SFProText-SemiboldItalic.svg#SFProText-SemiboldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'SFProDisplay_Semibold';
    src: url('/static/fonts/SFProDisplay-Semibold.eot');
    src: url('/static/fonts/SFProDisplay-Semibold.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-Semibold.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-Semibold.woff') format('woff'),
        url('/static/fonts/SFProDisplay-Semibold.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-Semibold.svg#SFProDisplay-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'SFProDisplay_SemiboldItalic';
    src: url('/static/fonts/SFProDisplay-SemiboldItalic.eot');
    src: url('/static/fonts/SFProDisplay-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-SemiboldItalic.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-SemiboldItalic.woff') format('woff'),
        url('/static/fonts/SFProDisplay-SemiboldItalic.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-SemiboldItalic.svg#SFProDisplay-SemiboldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'SFProText_Bold';
    src: url('/static/fonts/SFProText-Bold.eot');
    src: url('/static/fonts/SFProText-Bold.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProText-Bold.woff2') format('woff2'),
        url('/static/fonts/SFProText-Bold.woff') format('woff'),
        url('/static/fonts/SFProText-Bold.ttf') format('truetype'),
        url('/static/fonts/SFProText-Bold.svg#SFProText-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SFProText_BoldItalic';
    src: url('/static/fonts/SFProText-BoldItalic.eot');
    src: url('/static/fonts/SFProText-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProText-BoldItalic.woff2') format('woff2'),
        url('/static/fonts/SFProText-BoldItalic.woff') format('woff'),
        url('/static/fonts/SFProText-BoldItalic.ttf') format('truetype'),
        url('/static/fonts/SFProText-BoldItalic.svg#SFProText-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'SFProDisplay_Bold';
    src: url('/static/fonts/SFProDisplay-Bold.eot');
    src: url('/static/fonts/SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-Bold.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-Bold.woff') format('woff'),
        url('/static/fonts/SFProDisplay-Bold.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-Bold.svg#SFProDisplay-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SFProDisplay_BoldItalic';
    src: url('/static/fonts/SFProDisplay-BoldItalic.eot');
    src: url('/static/fonts/SFProDisplay-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-BoldItalic.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-BoldItalic.woff') format('woff'),
        url('/static/fonts/SFProDisplay-BoldItalic.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-BoldItalic.svg#SFProDisplay-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'SFProText_Heavy';
    src: url('/static/fonts/SFProText-Heavy.eot');
    src: url('/static/fonts/SFProText-Heavy.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProText-Heavy.woff2') format('woff2'),
        url('/static/fonts/SFProText-Heavy.woff') format('woff'),
        url('/static/fonts/SFProText-Heavy.ttf') format('truetype'),
        url('/static/fonts/SFProText-Heavy.svg#SFProText-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'SFProText_HeavyItalic';
    src: url('/static/fonts/SFProText-HeavyItalic.eot');
    src: url('/static/fonts/SFProText-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProText-HeavyItalic.woff2') format('woff2'),
        url('/static/fonts/SFProText-HeavyItalic.woff') format('woff'),
        url('/static/fonts/SFProText-HeavyItalic.ttf') format('truetype'),
        url('/static/fonts/SFProText-HeavyItalic.svg#SFProText-HeavyItalic') format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'SFProDisplay_Heavy';
    src: url('/static/fonts/SFProDisplay-Heavy.eot');
    src: url('/static/fonts/SFProDisplay-Heavy.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-Heavy.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-Heavy.woff') format('woff'),
        url('/static/fonts/SFProDisplay-Heavy.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-Heavy.svg#SFProDisplay-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'SFProDisplay_HeavyItalic';
    src: url('/static/fonts/SFProDisplay-HeavyItalic.eot');
    src: url('/static/fonts/SFProDisplay-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-HeavyItalic.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-HeavyItalic.woff') format('woff'),
        url('/static/fonts/SFProDisplay-HeavyItalic.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-HeavyItalic.svg#SFProDisplay-HeavyItalic') format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'SFProDisplay_Black';
    src: url('/static/fonts/SFProDisplay-Black.eot');
    src: url('/static/fonts/SFProDisplay-Black.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-Black.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-Black.woff') format('woff'),
        url('/static/fonts/SFProDisplay-Black.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-Black.svg#SFProDisplay-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'SFProDisplay_BlackItalic';
    src: url('/static/fonts/SFProDisplay-BlackItalic.eot');
    src: url('/static/fonts/SFProDisplay-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-BlackItalic.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-BlackItalic.woff') format('woff'),
        url('/static/fonts/SFProDisplay-BlackItalic.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-BlackItalic.svg#SFProDisplay-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'SFProDisplay_Thin';
    src: url('/static/fonts/SFProDisplay-Thin.eot');
    src: url('/static/fonts/SFProDisplay-Thin.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-Thin.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-Thin.woff') format('woff'),
        url('/static/fonts/SFProDisplay-Thin.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-Thin.svg#SFProDisplay-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'SFProDisplay_ThinItalic';
    src: url('/static/fonts/SFProDisplay-ThinItalic.eot');
    src: url('/static/fonts/SFProDisplay-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-ThinItalic.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-ThinItalic.woff') format('woff'),
        url('/static/fonts/SFProDisplay-ThinItalic.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-ThinItalic.svg#SFProDisplay-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'SFProDisplay_Ultralight';
    src: url('/static/fonts/SFProDisplay-Ultralight.eot');
    src: url('/static/fonts/SFProDisplay-Ultralight.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-Ultralight.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-Ultralight.woff') format('woff'),
        url('/static/fonts/SFProDisplay-Ultralight.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-Ultralight.svg#SFProDisplay-Ultralight') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'SFProDisplay_UltralightItalic';
    src: url('/static/fonts/SFProDisplay-UltralightItalic.eot');
    src: url('/static/fonts/SFProDisplay-UltralightItalic.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/SFProDisplay-UltralightItalic.woff2') format('woff2'),
        url('/static/fonts/SFProDisplay-UltralightItalic.woff') format('woff'),
        url('/static/fonts/SFProDisplay-UltralightItalic.ttf') format('truetype'),
        url('/static/fonts/SFProDisplay-UltralightItalic.svg#SFProDisplay-UltralightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
}
body {
  background-color: #f2f2f2;
  color: #202020;
  height: 100%;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  margin: 0;
  padding: 20px; }
  @media only screen and (max-width: 767px) {
    body {
      padding-right: 16px;
      padding-left: 16px; } }

.u-center-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.u-center-vertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.u-center-horizontal {
  position: relative;
  left: 50%;
  transform: translateX(-50%); }

.u-center-horizontal-inner {
  display: table;
  margin: 0 auto; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.text-left {
  text-align: left; }

h5 {
  font-size: 12px !important; }

.m-auto {
  margin: auto !important; }

.green {
  color: #accb54 !important; }

.red {
  color: #F25757 !important; }

.upper-case {
  text-transform: uppercase; }

.my-header {
  position: fixed;
  width: calc(100% - 40px);
  left: 0;
  top: 0;
  height: auto;
  background-color: #ffffff;
  z-index: 999;
  padding: 20px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .my-header .container-1 .item-in:first-child img {
    max-width: 200px; }
    @media only screen and (max-width: 767px) {
      .my-header .container-1 .item-in:first-child img {
        width: 120px; } }
  .my-header .container-1 .item-in:last-child {
    text-align: right; }
    .my-header .container-1 .item-in:last-child .logout-btn {
      padding: 4px 6px 6px 10px;
      color: #F25757;
      font-weight: 600;
      height: 100%; }
    .my-header .container-1 .item-in:last-child .notifications-button {
      float: right;
      color: #fbc02d; }
    @media only screen and (max-width: 427px) {
      .my-header .container-1 .item-in:last-child h5 {
        height: 100%;
        padding-top: 5px; }
        .my-header .container-1 .item-in:last-child h5 .logout-btn {
          height: auto;
          padding: 0px 6px 0px 10px; } }

.logo-login {
  max-width: 200px;
  margin-top: 40px;
  margin-bottom: 40px; }

.card-login {
  padding: 40px; }
  .card-login h1 {
    margin-bottom: 20px;
    color: #accb54; }
  .card-login .form-login .form-login-field {
    width: 100%;
    margin-bottom: 40px; }

.form-login-field > div:after {
  border-bottom: 2px solid #accb54 !important; }

.form-login-field_label-focus {
  color: #accb54 !important; }

.btn-sinko {
  background-color: #accb54 !important;
  color: rgba(0, 0, 0, 0.54) !important;
  font-weight: 700 !important; }
  .btn-sinko svg {
    margin-left: 7px; }

.btn-file {
  width: 100%;
  background-color: #008e2a !important;
  color: #ffffff !important; }

.list-tab-sinko {
  position: absolute !important;
  left: 0;
  bottom: 0;
  margin-bottom: 0;
  height: calc(100% - 95px); }
  .list-tab-sinko .p-b-50 {
    padding-bottom: 50px; }
  .list-tab-sinko .MuiTabs-root-208 {
    position: fixed;
    bottom: 0;
    width: 100%; }
  .list-tab-sinko .custom-label-color {
    position: absolute !important;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #accb54; }
    .list-tab-sinko .custom-label-color button span span span {
      font-weight: 700 !important; }

.font-tab {
  font-weight: 700 !important; }
  @media only screen and (max-width: 767px) {
    .font-tab {
      font-size: 11px !important; } }

.search-box {
  margin-top: 0; }
  .search-box .search-input {
    width: calc(100% - 48px); }

.content-tab {
  padding-top: 100px;
  padding-bottom: 70px; }

.form-visits .input-vists {
  width: 100%; }
  .form-visits .input-vists div:after {
    border-bottom: 2px solid #accb54 !important; }

.margin-input {
  margin-top: 32px !important; }

@media only screen and (max-width: 850px) {
  .resposive-filter .responsive-from {
    width: calc(50% - 8px);
    margin: 0 !important;
    margin-top: 16px !important;
    padding-right: 8px; }
  .resposive-filter .responsive-to {
    width: calc(50% - 8px);
    margin: 0 !important;
    margin-top: 16px !important;
    padding-left: 8px; }
    .resposive-filter .responsive-to label {
      padding-left: 8px; }
  .resposive-filter .responsive-select-agent {
    width: calc(50% - 8px);
    margin: 0 !important;
    margin-top: 16px !important;
    padding-right: 8px; } }

.p-title-table {
  padding: 24px 24px 0px 24px;
  position: relative; }
  .p-title-table .map-button {
    position: absolute;
    left: 140px;
    top: 18px;
    background-color: #008e2a;
    color: #ffffff; }
    .p-title-table .map-button:hover {
      background-color: #accb54; }

.no-padding {
  padding: 4px !important; }
  .no-padding.first {
    padding-left: 24px !important; }

.modal .modal-title {
  background-color: #accb54; }
  .modal .modal-title h2 {
    color: #ffffff;
    text-align: center; }
  .modal .modal-title .btn-close {
    position: absolute;
    top: 6px;
    right: 6px;
    color: #ffffff; }

.modal .modal-body .memo-cont {
  position: relative;
  border-bottom: 2px solid #f2f2f2;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px; }
  .modal .modal-body .memo-cont .visit-state {
    background-color: #008e2a;
    color: #ffffff;
    margin-bottom: 8px; }
  .modal .modal-body .memo-cont h3 {
    color: #202020;
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: 200; }
    .modal .modal-body .memo-cont h3 span {
      font-weight: 400;
      color: #accb54; }
  .modal .modal-body .memo-cont h4 {
    margin-top: 0;
    font-weight: 400; }
  .modal .modal-body .memo-cont p {
    color: #202020;
    font-style: italic;
    margin: 15px 0; }
  .modal .modal-body .memo-cont button {
    color: #ffffff;
    min-width: 120px; }

.cont-map {
  height: 600px; }
  @media only screen and (max-width: 991px) {
    .cont-map {
      height: 100%; } }

.cont-marker {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .cont-marker .info-box {
    height: auto;
    width: 230px;
    text-align: center;
    background-color: #ffffff;
    position: absolute;
    top: -140px;
    left: -250px;
    border-radius: 20px;
    border-bottom-right-radius: 0;
    padding: 8px 8px 20px 8px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12); }
    .cont-marker .info-box h2 {
      margin-top: 0;
      margin-bottom: 5px; }
    .cont-marker .info-box p {
      margin-top: 0;
      margin-bottom: 15px;
      color: #6d6d6d; }
    .cont-marker .info-box button {
      background-color: #008e2a;
      color: #ffffff; }

.map-error-text {
  text-align: center;
  padding: 20px; }

.marker1 {
  color: #019640; }

.marker2 {
  color: #3885cf; }

.marker3 {
  color: #d63131; }

.attachment-cont {
  /* Safari */
  display: flex !important; }
  .attachment-cont .attachment-name {
    flex-grow: 1;
    cursor: pointer;
    padding-left: 12px;
    padding-top: 12px;
    text-decoration: underline; }
  .attachment-cont .attachment-del {
    margin-top: 8px;
    cursor: pointer; }

