// FONTS

$SFProTextRegular: "SFProText_Regular";
$SFProTextRegularItalic: "SFProText_RegularItalic";
$SFProTextLight: "SFProText_Light";
$SFProTextLightItalic: "SFProText_LightItalic";
$SFProTextMedium: "SFProText_Medium";
$SFProTextMediumItalic: "SFProText_MediumItalic";
$SFProTextSemibold: "SFProText_Semibold";
$SFProTextSemiboldItalic: "SFProText_SemiboldItalic";
$SFProTextBold: "SFProText_Bold";
$SFProTextBoldItalic: "SFProText_BoldItalic";
$SFProTextHeavy: "SFProText_Heavy";
$SFProTextHeavyItalic: "SFProText_HeavyItalic";
$SFProDisplayBlack: "SFProDisplay_Black";
$SFProDisplayBlackItalic: "SFProDisplay_BlackItalic";

// COLORS

$black: #202020;
$white: #ffffff;
$light-grey: #f2f2f2;
$dark-grey: #6d6d6d;
$green: #accb54;
$dark-green: #008e2a;
$red: #F25757;
$warning: #fbc02d;

body {
  background-color: $light-grey;
  color: $black;
  height: 100%;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  margin: 0;
  padding: 20px;
  @media only screen and (max-width: 767px){
    padding-right: 16px;
    padding-left: 16px;
  }
}

.u-center-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.u-center-vertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.u-center-horizontal {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.u-center-horizontal-inner {
  display: table;
  margin: 0 auto;
}

// TEXT

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

h5 {
  font-size: 12px !important;
}
// MARGIN

.m-auto {
  margin: auto !important;
}

.green {
  color: $green !important;
}

.red {
  color: $red !important;
}

.upper-case {
  text-transform: uppercase;
}
